;(function() {
"use strict";

window.tmr= {
    "code_version":         "0",
    "rollbar_access_token": "198e4b120a754c0aae08ab3198d7fe55",
    "rollbar_active_for_dev": false,
    "environment":
        (window.location.hostname.match( /tmr-admin-([^.]+)\.data-online\.net\.au/ )||[])[1] // staging and testing
        || (/tmr-admin\.data-online\.net\.au/.test(window.location.hostname) && 'production')
        || 'development'

};
window.tmr.code_version='ae7b1767528abf6fffd612442691a7b5ab1fcd7e';
}());
